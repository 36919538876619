import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthenticationGuard } from './authentication/authentication-guard';
import { SamlauthenticationComponent } from './samlauthentication/samlauthentication.component';
import { FirsthomeComponent } from './firsthome/firsthome.component';
import { ContactsupportComponent } from './contactsupport/contactsupport.component';

import { GetpasswordComponent } from './getpassword/getpassword.component';
const routes: Routes = [
  { path: 'login', pathMatch: "full", component: SamlauthenticationComponent },
  { path: 'index', pathMatch: "full", component: FirsthomeComponent },
  { path: 'getpassword', pathMatch: "full", component: GetpasswordComponent },
  // { path: 'candidate-details', pathMatch: "full", component: ExamInstructionComponent },
// { path: '', pathMatch: "full", component: SamlauthenticationComponent },
// { path: '', pathMatch: "full", component: ContactsupportComponent },
  { path: 'contactsupport', pathMatch: "full", component: ContactsupportComponent },
//  { path: 'exams', loadChildren: () => import('../app/exam-client/exam-client.module').then(m => m.ExamClientModule) },
  { path: 'member', loadChildren: () => import('../app/member/member.module').then(m => m.MemberModule), canActivate: [AuthenticationGuard] },
  { path: ':lang/' + environment.authentication, loadChildren: './authentication/authentication.module#AuthenticationModule' },
  { path: ':lang/' + environment.support, loadChildren: './support/support.module#SupportModule', canActivate: [AuthenticationGuard] },
  { path: 'app', loadChildren: () => import('../app/application/application.module').then(m => m.ApplicationModule) },
  { path: 'adminlogin', pathMatch: "full", redirectTo: 'en-us/' + environment.authentication + '/signin' }, 
   { path: '', pathMatch: "full",redirectTo:'index' },
   /*{ path: '**', loadChildren: './not-found/not-found.module#NotFoundModule' }, */

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
